<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Inversionistas</h1>
		</div>
		<br />

		<div class="d-flex flex-wrap">
      <iframe title="Portal inversionistas - BALANCE ACTIVOS"
              width="100%"
              height="540"
              src="https://app.powerbi.com/view?r=eyJrIjoiOWYwMzU4NTMtNjBmNC00OTAwLWIyZmQtZDFhZDdhOGE5NWEyIiwidCI6ImVhY2I1MWZiLTY0M2QtNDk1NS05MTc1LTJkY2MzZGJkZTJjOSJ9"
              frameborder="0"
              allowFullScreen="true"
      >
      </iframe>
		</div>


	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = {title: 'Inversionistas'};
			this.$store.commit('setMenu',[menu]);
		}
	}
</script>
